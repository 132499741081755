<template>
  <div>
    <!--    <appbar @drawer="open" />-->
    <!--    <appdrawer :drawer="value"></appdrawer>-->
    <v-snackbar
      v-model="showSnackBar"
      :timeout="4000"
      top
      right
      dense
      :color="responseType"
    >
      <span class="white--text">{{ responseMessage }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
    <template>
      <v-card>
        <v-toolbar color="primary" dark flat prominent>
          <div class="contain1">
            <div class="contain">
              <div>
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2"
                      fab
                      dark
                      small
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-badge color="green" content="6">
                        <v-icon color="black">
                          mdi-bell
                        </v-icon>
                      </v-badge>
                    </v-btn>
                  </template>
                  <span>Notifications</span>
                </v-tooltip> -->
                <v-menu
                  offset-y
                  content-class="dropdown-menu"
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on }">
                    <a v-on="on"
                      ><v-icon size="47" left>mdi-account-circle</v-icon>
                    </a>
                  </template>
                  <v-card tile>
                    <v-list dense>
                      <v-list-item
                        nudge-right="50px"
                        v-for="(option, idx) in options"
                        :key="idx"
                        @click="state(option.title)"
                      >
                        <v-list-item-title class="ma-n1" @click="state()">{{
                          option.title
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </div>

            <div class="contain2"><h2>Transport Manager Portal</h2></div>
          </div>

          <template v-slot:extension>
            <v-tabs v-model="tabs" centered>
              <v-tab
                v-for="(item, index) in items"
                :key="index"
                :to="{ path: item.link }"
              >
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-sheet
          style="height: 100vh; background-color:#f8f8f8"
          id="scrolling-techniques-7"
          class="overflow-y-auto"
        >
          <v-main style="height: 100vh;"><router-view /></v-main>
        </v-sheet>

        <!--        <v-tabs-items v-model="tabs">-->
        <!--          <v-tab-item>-->

        <!--          </v-tab-item>-->

        <!--        </v-tabs-items>-->
      </v-card>
    </template>
    <div class="text--center">
      <v-dialog v-model="logoutDialog" width="300">
        <v-card>
          <v-card-title
            class="title  white--text"
            style="background-color: #47173a"
          >
            Logout
          </v-card-title>
          <v-spacer />
          <v-card-title class="justify-left my-5 mb-3 subtitle-1 transparent">
            Are you sure you want to log out?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              @click="logoutDialog = false"
              class="text-capitalize font-weight-bold"
              >No</v-btn
            >
            <v-btn
              small
              @click="logout()"
              class=" text-capitalize font-weight-bold"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text--center">
      <v-dialog v-model="passwordDialog" width="450">
        <v-card>
          <v-card-title class="title primary white--text" primary-subtitle>
            <span class=" font font-weight-medium">Change Password</span>
          </v-card-title>
          <v-card-text>
            <v-spacer />
            <v-progress-linear
              indeterminate
              v-show="progress"
              color="blue"
            ></v-progress-linear>
            <v-spacer />

            <v-text-field
              class="mt-5  blue-input mx-5"
              outlined
              v-model="password"
              ref="Password"
              label="Old Password"
              id="Password"
              :error-messages="passwordMessage"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              name="password"
              :type="show ? 'text' : 'password'"
              :rules="[rules.requiredPassword, rules.min]"
              @click:append="show = !show"
            />
            <v-text-field
              class="mt-n1 blue-input mx-5"
              outlined
              v-model="newPassword"
              ref="newPassword"
              id="newPassword"
              label="New Password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              name="newPassword"
              :error-messages="newPasswordMessage"
              :rules="[rules.requiredPassword, rules.min]"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
            />
            <v-text-field
              class="mt-n1 blue-input mx-5"
              outlined
              v-model="confirmPassword"
              label="Confirm Password"
              id="confirmPassword"
              :rules="[passwordConfirmationRule]"
              :error-messages="confirmPasswordMessage"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              name="confirmPassword"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              medium
              @click="passwordDialog = false"
              class=" text-capitalize"
              >No</v-btn
            >
            <v-btn
              medium
              @click="changePassword()"
              class="primary text-capitalize mr-7"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="dialog" width="400px">
      <v-progress-linear
        indeterminate
        v-show="progress"
        color="primary"
      ></v-progress-linear>

      <v-card>
        <v-card-title>
          <span
            style="margin-left: 32px"
            class="subtitle-1 font-weight-bold blue-grey--text"
            >Profile</span
          >
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3 required" @submit.prevent="update">
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="Name"
                  ref="name"
                  class="mx-3  required"
                  v-model="editedItem.name"
                  :rules="[rules.requiredName]"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  label="Username"
                  ref="username"
                  class="mx-3  required"
                  v-model="editedItem.username"
                  :rules="[rules.requiredName]"
                >
                </v-text-field>
                <v-text-field
                  dense
                  readonly
                  outlined
                  label="Email"
                  ref="email"
                  class="mx-3 required"
                  v-model="editedItem.email"
                  :rules="[rules.requiredEmail]"
                >
                </v-text-field>

                <v-text-field
                  dense
                  outlined
                  label="Phone"
                  class="mx-3  required"
                  ref="phone"
                  v-model="editedItem.mobile"
                  :rules="[rules.requiredPhone, rules.min]"
                >
                </v-text-field>
              </v-col>
              <v-row class="mt-3">
                <v-spacer />
                <v-btn
                  text
                  class=" px-1 mt-n5 mb-4 ml-10"
                  medium
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  medium
                  type="submit"
                  class="primary  px-3 mt-n5 mb-4 ml-5 mr-10"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Drawer from "./Drawer";
// import Header from "./Header";
import * as axios from "axios";

export default {
  name: "Master",
  components: {
    // appbar: Header,
    // appdrawer: Drawer
  },
  data() {
    return {
      value: true,
      tabs: null,
      logoutDialog: false,
      passwordDialog: false,
      show: false,
      show1: false,
      show2: false,
      progress: false,
      showSnackBar: false,
      dialog: false,
      password: "",
      newPassword: "",
      previewImage: "",
      responseType: "",
      // file:"",
      confirmPassword: "",
      itemObject: {
        password: null,
        newPassword: null,
        confirmPassword: null
      },
      editedItem: {
        name: "",
        email: "",
        mobile: "",
        username: ""
      },
      errorMessage: "",
      passwordMessage: "",
      confirmPasswordMessage: "",
      responseMessage: "",
      newPasswordMessage: "",
      formHasErrors: false,
      rules: {
        // requiredPassword: value => !!value || "Password is required.",
        requiredPassword: value =>
          !!value || value === null || "Password is required.",
        min: value => value.length >= 6 || "At least 6 characters",
        requiredName: value => !!value || value === null || "Name is required.",
        requiredEmail: value =>
          !!value || value === null || "Email is required.",
        requiredPhone: value => !!value || value === null || "Name is required."
      },
      options: [
        { title: "Profile" },
        { title: "Change Password" },
        { title: "Logout" }
      ],
      items: [
        {
          title: "Dashboard",
          link: "/app/dashboard"
        },
        {
          title: "Pending Orders",
          link: "/app/pending"
        },
        {
          title: "Reviewed Orders",
          link: "/app/reviewed"
        },
        {
          title: "Cheques",
          link: "/app/cheques"
        }
      ]
    };
  },
  computed: {
    form() {
      return {
        password: this.password,
        newPassword: this.newPassword
      };
    },

    passwordConfirmationRule() {
      return () =>
        this.confirmPassword === this.newPassword || "Password must match";
    }
  },
  methods: {
    // processFile(event) {
    //   this.file = event;
    //   if (event) {
    //     const reader = new FileReader();
    //     reader.onload = e => {
    //       this.previewImage = e.target.result;
    //     };
    //     reader.readAsDataURL(event);
    //   }
    // },

    getUserDetails() {
      this.ready = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/user/profile/me`
      })
        .then(response => {
          this.editedItem = response.data;
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.logout();
          } else {
            // console.log(err);
          }
        });
    },

    changePassword() {
      this.progress = true;
      if (this.confirmPassword === this.newPassword) {
        setTimeout(() => {
          axios({
            method: "put",
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: `${process.env.VUE_APP_BASE}/user/change/password`,
            data: {
              oldPassword: this.password,
              newPassword: this.newPassword,
              confirmPassword: this.newPassword
            }
          })
            .then(() => {
              this.progress = false;
              this.passwordDialog = false;
              this.showSnackBar = true;
              this.responeType = "success";
              this.responseMessage = "Password updated successfully";
              this.password = this.itemObject.password;
              this.newPassword = this.itemObject.newPassword;
              this.confirmPassword = this.itemObject.confirmPassword;
            })
            .catch(err => {
              // console.log(err);
              this.showSnackBar = true;
              this.progress = false;
              this.responseMessage = Array.isArray(err?.response?.data?.message)
                ? err?.response?.data?.message?.join(",")
                : err?.response?.data?.message;
              this.responseType = "red";
              if (err.response.status === 401) {
                this.logout();
              }
            });
        }, 5000);
      } else {
        this.responseMessage = "All fields required";
        this.responseType = "error";
        // console.log("passwords don't match");
      }
    },

    update() {
      if (
        this.editedItem.name !== "" &&
        this.editedItem.mobile !== "" &&
        this.editedItem.email !== "" &&
        this.editedItem.username !== ""
      ) {
        this.progress = true;
        axios({
          method: "put",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/user/profile/me`,
          data: {
            name: this.editedItem.name,
            email: this.editedItem.email,
            mobile: this.editedItem.mobile,
            username: this.editedItem.username
          }
        })
          .then(response => {
            this.progress = false;
            if (response.status === 200) {
              this.showSnackBar = true;
              this.responseType = "success";
              this.responseMessage = "Profile has been updated successfully";
              setTimeout(() => {
                this.dialog = false;
              }, 2000);
            }
          })
          .catch(err => {
            this.showSnackBar = true;
            this.responseMessage = "Profile update failed";
            this.responseType = "red";
            if (err.response.status === 401) {
              this.logout();
            }
          });
      } else {
        this.showSnackBar = true;
        this.responseType = "red";
        this.responseMessage = `All fields must be filled correctly. `;
      }
    },

    state(value) {
      switch (value) {
        case "Profile":
          this.getUserDetails();
          this.dialog = true;
          //this.$router.push({ name: "master.profile" });
          break;
        case "Change Password":
          this.passwordDialog = true;
          break;
        case "Logout":
          this.alert();
          break;
        default:
          break;
      }
    },

    alert() {
      this.$swal("Are you sure you want to log out?", {
        buttons: {
          cancel: true,
          confirm: "Yes"
        }
      }).then(value => {
        if (value) {
          this.logout();
        }
      });
    },
    expand() {
      this.drawer = !this.drawer;
      this.$emit("drawer", this.drawer);
    },

    logout() {
      localStorage.clear();
      this.$router.replace({ path: "/login" });
    }
  }
  // methods: {
  //   open(value) {
  //     this.value = value;
  //   }
  // }
};
</script>
<style scoped>
.contain {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  alignt-content: stretch;
}

.contain1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.contain2 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}
</style>
